import { useSmtpConfiguradoQuery } from 'graphql/hooks.generated'

export function useIsSmtpConfigurado() {
  const {
    // TODO (Codebenders): utilizar esta hook nos demais componentes do front onde essa query é chamada #23200
    data: { info: { smtpConfigurado } = {} },
  } = useSmtpConfiguradoQuery({
    fetchPolicy: 'cache-first',
  })
  return smtpConfigurado
}
